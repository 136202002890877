import React, { useState, useEffect } from "react";
import "./HomePage.css";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function Homepage() {
  const navigate = useNavigate();
  const { user, loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();
  const [loginMessage, setLoginMessage] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem("email", user.email);
      localStorage.setItem("isLogin", true);
      setLoginMessage("Login Successful!");
      navigate("/edit")
    } else {
      localStorage.setItem("email", "");
      localStorage.setItem("isLogin", false);
      setLoginMessage("");
    }
  }, [isAuthenticated]);

  const handleLogin = async () => {
    try {
      await loginWithRedirect().then();
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className="image-container">
      <div className="centered-content">
        <img src="https://www.ocard.uk/cdn/shop/files/download_1.png?v=1680466491&width=130" />

        {isAuthenticated ? (
          <>
            <p>{loginMessage}</p>
            <Button onClick={handleLogout}>Log Out</Button>
          </>
        ) : (
          <Button onClick={handleLogin}>Log In</Button>
        )}
      </div>
      <div></div>
    </div>
  );
}

export default Homepage;
