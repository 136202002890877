import React, { useState } from "react";
import axios from "axios";
import "./Login.css"; // Import your CSS file
import MyForm from "./MyForm";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("https://backend.ocard.uk/api/login", {
        username,
        password,
      });
      console.log("Login success:", response.data);

      // Save token to localStorage
      localStorage.setItem("token", response.data.token);

      // Redirect to the dashboard or a specific route on successful login
      navigate("/edit");
      // Replace '/dashboard' with your desired route
      // Handle success, store token, redirect, etc.
    } catch (error) {
      console.error("Login error:", error.response.data);
      setLoginError("Invalid username or password");
    }
  };

  return (
    <>
      {!token ? (
        <div className="login-container">
          <form onSubmit={handleLogin} className="login-form">
            <h2>Login</h2>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            {loginError && <p className="error-message">{loginError}</p>}
            <button type="submit">Login</button>
          </form>
        </div>
      ) : (
        <MyForm />
      )}
    </>
  );
};

export default Login;
