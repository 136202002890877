import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import spinner from '../assets/Intersection.gif';
import { MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { PiBagFill } from "react-icons/pi";
import { FaLink } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaTelegramPlane } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";
import { FaRupeeSign } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import "./Card.css";

const Card = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userNotFound, setUserNotFound] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://backend.ocard.uk/api/myform/${id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setFormData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setUserNotFound(true);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (userNotFound) {
      navigate("/not-available");
    }
  }, [userNotFound, navigate]);


  function getInstagramIdFromLink(link) {
    const usernameRegex =
      /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([A-Za-z0-9_]+)/;
    const match = link.match(usernameRegex);

    if (match && match[1]) {
      return match[1]; // Extracted username from the link
    } else {
      return null; // Link doesn't match the Instagram format
    }
  }

  function getLinkedInIdFromLink(link) {
    const usernameRegex =
      /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([A-Za-z0-9_-]+)/;
    const match = link.match(usernameRegex);

    if (match && match[1]) {
      return match[1]; // Extracted username from the link
    } else {
      return null; // Link doesn't match the LinkedIn format
    }
  }
  function getFacebookIdFromLink(link) {
    const usernameRegex =
      /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(pages\/)?([A-Za-z0-9._%-]+)/;
    const match = link.match(usernameRegex);

    if (match && match[2]) {
      return match[2]; // Extracted username or page name from the link
    } else {
      return null; // Link doesn't match the Facebook format
    }
  }
  function getTwitterIdFromLink(link) {
    const usernameRegex =
      /(?:https?:\/\/)?(?:www\.)?twitter\.com\/([A-Za-z0-9_]+)/;
    const match = link.match(usernameRegex);

    if (match && match[1]) {
      return match[1]; // Extracted username from the link
    } else {
      return null; // Link doesn't match the Twitter format
    }
  }

  return (
    <>
      <div className="main">
        {loading ? (
          <div className="spinner-container">
            <img
              src={spinner}
              style={{ width: '100px', margin: 'auto', display: 'block', marginTop: '190px', loading: 'lazy' }}
              alt="Loading..."
            />
          </div>
        ) : (


          <div className="card-div">
            {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
            <div className="user-profile-div">
              <img
                src={formData.user_profile}
                // src="https://139.99.53.236:8443/rrcer/images/slideshow/slider1.jpg"
                alt="user-profile"
                className="profile" />
            </div>
            {/* <svg
      xmlns="http://www.w3.org/2000/svg"
      className="wave1"
      viewBox="0 0 1440 320"
    >
      <path
        className="overlay"
        fill="#c5261b"
        fill-opacity="1"
        d="M0,256L80,256C160,256,320,256,480,229.3C640,203,800,149,960,138.7C1120,128,1280,160,1360,176L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="wave2"
      viewBox="0 0 1440 320"
    >
      <path
        className="overlay"
        fill="white"
        fill-opacity="1"
        d="M0,256L80,256C160,256,320,256,480,229.3C640,203,800,149,960,138.7C1120,128,1280,160,1360,176L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg> */}

            <div className="icondiv">
              <img src={formData.user_icon} alt="icon" className="icon" />
            </div>

            <div className="profile-details">
              <h1>{formData.name} </h1>
              <h2>{formData.title}</h2>
              <h2>{formData.department}</h2>
              <h2>{formData.company}</h2>
            </div>

            <h2 className="media">{formData.headline}</h2>

            <div className="contact-details">
              {formData.email ? (
                <a href={"mailto:" + formData.email} className="custom-link">
                  <div className="email-box">
                    <div className="mobile-icon">
                      <MdEmail
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>{formData.email}</h3>
                      {/* <h4>work</h4> */}
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.phone ? (
                <a href={`tel:+91` + formData.phone} className="custom-link">
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <BsTelephoneFill
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>+91 {formData.phone}</h3>
                      {/* <h4>work</h4> */}
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.whatsapp ? (
                <a
                  href={`https://wa.me/+91` + formData.whatsapp}
                  className="custom-link"
                >
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <IoLogoWhatsapp
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>+91 {formData.whatsapp}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.telegram ? (
                <a
                  href={`https://t.me/+91` + formData.telegram}
                  className="custom-link"
                >
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <FaTelegramPlane
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>+91 {formData.telegram}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.websiteurl ? (
                <a href={formData.websiteurl} className="custom-link">
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <PiBagFill
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>{formData.websiteurl}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.link ? (
                <a href={formData.link} className="custom-link">
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <FaLink
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>{formData.link}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.instagram ? (
                <a href={formData.instagram} className="custom-link">
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <AiFillInstagram
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>{`@${getInstagramIdFromLink(formData.instagram)}`}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.linkedin ? (
                <a href={formData.linkedin} className="custom-link">
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <FaLinkedinIn
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>{`@${getLinkedInIdFromLink(formData.linkedin)}`}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.facebook ? (
                <a href={formData.facebook} className="custom-link">
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <FaFacebookF
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>{`@${getFacebookIdFromLink(formData.facebook)}`}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.discord ? (
                <a href={formData.discord} className="custom-link">
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <BsDiscord
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>{formData.discord}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.xcom ? (
                <a href={formData.xcom} className="custom-link">
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <FaTwitter
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>{`@${getTwitterIdFromLink(formData.xcom)}`}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
              {formData.upi ? (
                <a href={`upi://${formData.upi}`} className="custom-link">
                  <div className="mobile-box">
                    <div className="mobile-icon">
                      <FaRupeeSign
                        style={{ height: "25px", width: "25px", color: "white" }} />
                    </div>
                    <div className="text-style">
                      <h3>{formData.upi}</h3>
                    </div>
                  </div>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {/* <button className="savebtn">SAVE CONTACT</button> */}
      </div>
    </>
  );
};


export default Card;
