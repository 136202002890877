// import "./App.css";
import React from "react";
import HomePage from "./Components/HomePage";
import Login from "./Components/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Change this line
import Registration from "./Components/Registration";
import MyForm from "./Components/MyForm";
import Card from "./Components/Card";
import Edit from "./Components/Edit";
import { useAuth0 } from "@auth0/auth0-react";
import UserNotFound from "./Components/UserNotFound";

function App() {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  console.log(user);

  return (
    // Change the following line
    <Router>
      {/* {isAuthenticated ? <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Log Out</button> : <button onClick={() => loginWithRedirect()}>Log In</button>} */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/addform" element={<MyForm />} />
        <Route path="/profile/:id" element={<Card />} />
        <Route path="/not-available" element={<UserNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
