import React, { useState } from "react";
import axios from "axios";

const Registration = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registrationError, setRegistrationError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setRegistrationError("Passwords do not match");
      return;
    }
    console.log("email and password: ", email, password);

    try {
      const response = await axios.post(
        "https://backend.ocard.uk/api/register",
        {
          username: email,
          password,
        }
      );
      console.log("Registration success:", response.data);
      // Handle success, redirect user, etc.
      alert("User Created Successfully");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setRegistrationError("");
    } catch (error) {
      console.error("Registration error:", error.response.data);
      setRegistrationError("Error : " + error.response.data.message);
    }
  };

  return (
    <div className="registration-container">
      <form onSubmit={handleRegistration} className="registration-form">
        <h2>Register</h2>

        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <br />
        <br />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          required
        />
        <br />
        <br />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          required
        />
        <br />
        <br />
        {registrationError && (
          <p className="error-message">{registrationError}</p>
        )}
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Registration;
